.action-dialog-container {
  width: 200px;
  /* height: 340px; */
  opacity: 1;
  position: fixed;
  z-index: 9;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
  overflow: hidden;
  overflow-x: hidden;
  padding-bottom: 5px;
}

.action-dialog-actions-container {
  font-size: 15px;
  text-align: center;
  line-height: 14px;
  opacity: 1;
  margin-top: 5px;
  /* position: absolute;
  top: 83px;
  left: 29px; */
}
.action-dialog-add,
.action-dialog-delete,
.action-dialog-edit {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 15px;
}

.action-name {
  font-size: 15px;
  text-align: center;
  line-height: 25px;
  margin-left: 15px;
  font-weight: 500;
}
.action-dialog-book-info {
  margin-left: 10px;
  overflow-y: scroll;
  height: 185px;
}
.action-dialog-book-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  max-height: 190px;
  margin-bottom: 10px;
}
.action-dialog-book-author,
.action-dialog-book-desc,
.action-dialog-book-publisher,
.action-dialog-book-added {
  font-size: 15px;
  line-height: 18px;
}
.action-dialog-book-detail {
  /* width: 80%; */
  margin-top: 5px;
  max-height: 50px;
  font-size: 15px;
}
